/*======== generic-tabs =======*/
.generic-tabs {
  border-bottom: 0;
  @include border-radius(8px);
  @include box-shadow(0 0 10px rgba(82, 85, 90, 0.1));
  background-color: $white;
  padding-right: 20px;
  padding-left: 20px;
  position: relative;
  .nav-item {
    margin-bottom: 0;
    margin-right: 87px;
    @media #{$large_mobile} {
      margin-right: 20px;
    }
    @media #{$small_mobile} {
      margin-right: 20px;
    }
    &:first-child,
    &:last-child{
      margin-right: 0;
    }
  }
  .nav-link {
    border: 0;
    @include border-radius(0);
    color: $theme-color-4;
    font-weight: $fw-medium;
    padding: 14px 0;
    @include transition(0.5s);
    @media #{$large_mobile} {
      border-bottom: 3px solid transparent;
    }
    @media #{$small_mobile} {
      border-bottom: 3px solid transparent;
    }
    &.active {
      color: $theme-color-2;
      border-bottom-color: $theme-color-2;
    }
  }
}
/*======== generic--tabs =======*/
.generic--tabs {
  @include border-radius(0);
  @include box-shadow(none);
  padding: 0;
  .nav-item {
    margin-right: 40px;
  }
}
/*======== generic--tabs-2 =======*/
.generic--tabs-2 {
  .nav-item {
    margin-right: 40px;
    &:first-child {
      margin-right: 40px;
    }
  }
  .nav-link {
    position: relative;
    @media #{$large_mobile} {
      border-bottom: 0;
    }
    @media #{$small_mobile} {
      border-bottom: 0;
    }
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 3px;
      bottom: 0;
      left: 0;
      background-color: $theme-color-2;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      @include transition(0.3s);
      @include transform(scale(0));
    }
    &.active {
      &::after {
        @include transform(scale(1));
      }
    }
  }
}
/*======== generic-tabs-layout-2 =======*/
.generic-tabs-layout-2 {
  @include border-radius(0);
  @include box-shadow(none);
  padding: 0;
  .nav-item {
    margin-right: 0;
    &:first-child {
      .nav-link {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
    &:last-child {
      .nav-link {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
  .nav-link {
    padding: 2px 12px;
    border: 1px solid rgba(128, 137, 150, 0.4);
    margin-left: -1px;
    font-size: $fs-15;
    font-weight: $fw-regular;
    &:hover {
      background-color: rgba(128, 137, 150, 0.06);
      border-color: rgba(128, 137, 150, 0.4);
    }
    &.active {
      background-color: rgba(128, 137, 150, 0.2);
      color: $theme-color;
      border-color: rgba(128, 137, 150, 0.4);
    }
  }
}
/*======== generic--tabs-layout-2 =======*/
.generic--tabs-layout-2 {
  .nav-item {
    margin-right: 10px;
    &:first-child {
      margin-right: 10px;
      .nav-link {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
      }
    }
    &:last-child {
      .nav-link {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
      }
    }
  }
  .nav-link {
    @include border-radius(30px);
    border: 0;
    &:hover {
      background-color: rgba(128, 137, 150, 0.1);
    }
    &.active {
      background-color: $theme-color-2;
      color: $white;
    }
  }
}
/*======== anim-bar =======*/
.anim-bar {
  display:inline-block;
  width: 30px;
  height: 3px;
  @include border-radius(4px);
  background-color: $theme-color-2;
  position: absolute;
  bottom: 0;
  left: 0;
  @include transition(0.5s);
  @media #{$large_mobile} {
    display: none;
  }
  @media #{$small_mobile} {
    display: none;
  }
}