/*
	Template Name: Disilab
	Author: TechyDevs
	Email: contact@techydevs.com
	Website: techydevs.com
	Description: Disilab -  Social Questions and Answers HTML Template
	Version: 1.0
*/
// variable scss
@import "partials/variables";

// mixin scss
@import "partials/mixins";
// common scss
@import "partials/common";
// button scss
@import "partials/button";
// keyframes scss
@import "partials/keyframes";
// file-upload scss
@import "partials/file-upload";
// modal scss
@import "partials/modal";
// comments scss
@import "partials/comments";
// call-to-action scss
@import "partials/call-to-action";
// contact scss
@import "partials/contact";
// info-box scss
@import "partials/info-box";
// collapse scss
@import "partials/collapse";
// progress-bar scss
@import "partials/progress-bar";
// icon-element scss
@import "partials/icon-element";
// table scss
@import "partials/table";
// cards scss
@import "partials/cards";
// custom scss
@import "partials/custom";
// tab scss
@import "partials/tab";
// header scss
@import "partials/header";
// hero scss
@import "partials/hero";
// footer scss
@import "partials/footer";

